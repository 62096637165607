@import url('https://fonts.googleapis.com/css?family=Rampart+One');
@import url('https://fonts.googleapis.com/css?family=Ubuntu');
@import url('https://fonts.googleapis.com/css?family=Ubuntu+Condensed');
@import url('https://fonts.googleapis.com/css?family=Pixelify+Sans');
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Audiowide');
@import url('https://fonts.googleapis.com/css?family=Playwrite+GB+S');

@media (prefers-reduced-motion: no-preference) {
  .rotImg {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
    background-color: #e5e4e4;
}
.navItem{
  text-decoration: none !important;
  padding: 15px 5px;
}
.sectionsContainer{
    padding-top: 100px;
    padding-bottom: 95px;
    min-height: 90vh;
    overflow-x: hidden;
    text-align: center;
}

.cuteCard{
  background-color: hsl(160, 60%, 100%);
  border-radius: 0.5rem;
  box-shadow: 2px 2px 6px 3px hsl(160, 10%, 90%);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  padding: 5px;
}

.korekContainer{
  background-image: url("static/images/bumi-bg.webp");
  opacity: .8;
  background-color: #e5e4e4;
  background-size: cover;
  padding-top: 95px;
  text-align: center;
}
.penguinContainer{
  background-image: url("static/images/penguin-bg.webp");
  opacity: .8;
  background-color: #e5e4e4;
  background-position: right;
  background-size: cover;
  padding-top: 95px;
  text-align: center;
}
.bulanContainer{
  background-image: url("static/images/bumibulan-bg.webp");
  /* opacity: .8; */
  background-color: #e5e4e4;
  background-position: center;
  background-size: cover;
  padding-top: 95px;
  text-align: center;
}
.spaceContainer{
  background-image: url("static/images/space-debris.png");
  /* opacity: .8; */
  background-color: #e5e4e4;
  background-position: center;
  background-size: cover;
  padding-top: 95px;
  text-align: center;
}
.rawakContainer{
    background-image: url("static/images/rawak-bg.webp");
    opacity: .8;
    background-color: #e5e4e4;
    background-size: cover;
}
.kodRahsiaContainer{
  background-image: url("static/images/penjara.webp");
  opacity: .8;
  background-size: cover;
}
.merdekaContainer{
  background-image: url("static/images/merdeka.webp");
  background-size: cover;
  background-position: center;
  width: 100%;
}
.calenderTop{
  background-color: #ee5253;
  max-width: 200px;
  border-style: solid;
  border-color: black;
  border-width: 3px 3px 0px 3px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: center;
}
.calenderMid{
  background-color: white;
  max-width: 200px;
  border-style: solid;
  border-color: black;
  border-width: 0px 3px 0px 3px;
}
.calenderBot{
  background-color: white;
  max-width: 200px;
  border-style: solid;
  border-color: black;
  border-width: 0px 3px 3px 3px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.contentBox{
  background-color: rgba(255, 255, 255, 0.9)
}
.birthDay{
  color: white;
  font-size: x-large;
  /* margin-top: 2px; */
}
.birthNo{
  font-size: 3.5em;
  font-weight: bolder;
}
.pmPic{
  max-width: 300px;
}
.diaryTxt{
  font-size: xx-large;
}
.ftrTxt{
  text-align: left;
  font-size: medium;
}
.cls-1{
  fill:#f40909;
  opacity:0;
}
.cls-2{
  fill:#9209ee;
  opacity:0;
}
.cls-3{
  fill:#1d16e4;
  opacity:0;
}
span.svg {
  display: block;
  margin: auto;
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
span.svg svg {
  width: 200px;
  height: 200px;
  max-width: 200px;
  margin: auto;
}
@keyframes fireworkone {
  0% {
    opacity: 0;
 }
  25% {
    opacity: 0.15;
 }
  50% {
    opacity: 0.3;
 }
  75% {
    opacity: 0.15;
 }
  100% {
    opacity: 0;
 }
}
@keyframes fireworktwo {
  0% {
    opacity: 0;
 }
  25% {
    opacity: 0.45;
 }
  50% {
    opacity: 0.8;
 }
  75% {
    opacity: 0.45;
 }
  100% {
    opacity: 0;
 }
}
@keyframes fireworthree {
  0% {
    opacity: 0;
 }
  25% {
    opacity: 0.55;
 }
  50% {
    opacity: 1;
 }
  75% {
    opacity: 0.55;
 }
  100% {
    opacity: 0;
 }
}
.firework-icon .cls-1, .firework-icon .cls-2, .firework-icon .cls-3 {
  opacity: 0;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.firework-icon .cls-3 {
  animation-name: fireworthree;
  animation-delay: 0.11s;
}
.firework-icon .cls-2 {
  animation-name: fireworktwo;
  animation-delay: 0.41s;
}
.firework-icon .cls-1 {
  animation-name: fireworkone;
  animation-delay: 0.71s;
}
@keyframes elastic-pulse {
  0% {
    transform: scale(0.4);
 }
  100% {
    transform: scale(1);
 }
}
.img-firework {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: elastic-pulse;
  animation-delay: 0s;
}
.bomTop{
  margin-bottom: 5vw;
}
.bomm{
  margin-top: 15vw;
}
#boom {
  position: absolute;
  border-radius: 50%;
  background-color: #404040;
  width: 20vh;
  height: 20vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  animation-name: boom;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

#fuse {
  position: absolute;
  background-color: whitesmoke;
  height: 10vh;
  width: 1vh;
  border-radius: 25%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  animation-name: fuse;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

#sparks {
  position: absolute;
  height: 20vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  animation-name: sparks;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

/* ANIMATIONS */

@keyframes sparks {
  0% {
    margin-top: -15vh;
  }
  50% {
    margin-top: -10vh;
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes boom {
  50% {
    width: 20vh;
    height: 20vh;
  }
  60% {
    width: 18vh;
    height: 18vh;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fuse {
  0% {
    height: 10vh;
    margin-top: -10vh;
  }
  50% {
    height: 1vh;
    margin-top: 0vh;
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes moveRightToLeft {
  0% {
      transform: translateX(400%);
  }
  100% {
      transform: translateX(-100%);
  }
}

@keyframes awanRightToLeft {
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-120%);
  }
}

.thiefContainer {
  position: relative;
  width: 100%;
  height: auto;
}

.movingImg {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 300px;
  animation: moveRightToLeft 15s linear infinite; /* Adjust the duration as needed */
}
.awanImg{
  animation: awanRightToLeft 25s linear infinite; /* Adjust the duration as needed */
}
.skyImg{
  max-width: 800px;
  margin-top: -20vw;
}
.merdekaImg{
  max-width: 400px;
}
.thiefBtm{
  margin-top: 18vw;
}
.covidImg{
  max-width: 200px;
  margin-top: 5vw;
  margin-bottom: 5vw;
}
@media only screen and (max-width: 768px) {
  .diaryTxt {
    font-size: large;
  }
  .bomm{
    margin-top: 70vw;
  }
  .bomTop{
    margin-bottom: 20vw;
  }
  .skyImg,.merdekaImg{
    max-width: 200px;
  }
  .thiefBtm{
    margin-top: 50vw;
  }
  @keyframes moveRightToLeft {
    0% {
        transform: translateX(150%);
    }
    100% {
        transform: translateX(-100%);
    }
  }
}
.bacaanTitle{
    font-family: "Rampart One";
    color: #fff200;
    padding: 140px;
    font-size: 5vw !important;
    text-shadow: 2px 2px #ff0000;
    text-align: end;
}
.interaktifTitle{
    font-family: "Rampart One";
    color: #fff200;
    font-size: 5vw !important;
    text-shadow: 2px 2px #ff0000;
    text-align: center;
}
.bacaanBtn{
    font-family: "Ubuntu";
    color: white;
}
.interaktifContainer{
    background-color: #e5e4e4;
    max-width: 1200px;
    margin: auto;
}
.eksperimenContainer{
    background-color: #e5e4e4;
}
.eksperimenTitleTxt{
    font-family: "Rampart One";
    font-size: 5vw !important;
    color: #fff200;
    text-shadow: 2px 2px #ff0000;
}
.eksperimenTxt{
    font-family: "Ubuntu";
    font-size: 2vw !important;
}
.ftrContainer{
    height: 100px;
    background-color: #fff200;
    text-align: center;
}
.ftrText{
    padding-top: 20px;
}
@font-face {
  font-family: Abys;
  src: url(./assets/Abys-Regular.otf);
}
@font-face {
  font-family: Aquire;
  src: url(./assets/AquireBold.otf);
}
.txtTitle {
  font-family: Abys;
  color: #2e2e48;
}
.imgMeme{
  width: 100%;
  max-width: 500px;
}
/* korek bumi page */
.txtContainer{
  background-color: white;
  opacity: .9;
}
.bodyText{
  font-size: large !important;
  text-align: justify !important;
}
/* lipatan kertas page */

.kertasContainer{
  background-image: url("static/images/kertas-bg.webp");
  background-size: cover;
}
#arrow {
  width: 15%;
}

#imgContainer {
  background-color: white;
}

#descContainer {
  background-color: white;
}
/* Jom Shopping Page*/
.shoppingContainer{
  background-image: url("static/images/shop-bg.webp");
  background-size: cover;
}
#main {
  margin-top: 20px;
}
p.name {
  font-size: larger;
  font-weight: bold;
}
p.price {
  font-size: large;
  color: darkorange;
}
#total {
  font-weight: bolder;
  text-align: center;
  background-color: yellow;
  padding: 10px;
}
.sticky {
  position: fixed;
  z-index: 300;
  overflow: hidden;
  top: 0;
  display: block;
  text-align: center;
  margin-top: 100px;
}
#receipt{
  text-align: center;
  background-color: white;
  max-width: 600px;
  padding: 20px;
  margin: auto;
  margin-top: 20px;
}
.calBtn{
  text-align: center;
}
div#receipt > p {
  text-align: center;
  background-color: aliceblue;
}
.logo {
  width: 200px;
  margin: 20px;
}

/* Checklist Kehidupan Page*/
.checklistContainer{
  background-image: url("static/images/checklist-bg.webp");
  background-size: cover;
}
.checklistTitle{
  text-align: center;
  font-family: monospace;
}
.chckbx{
  width: 20px;
  height: 20px;
  margin-top: 18px;
  margin-left: 5px;
}
.checklistContainer{
  margin: auto;
}
.cntnr{
  background-color: white;
}
.itemTxt{
  font-size: 1.25rem;
  margin-left: 30px;
  margin-top: 12px;
  font-family: 'Lucida Sans';
  display: inline-block;
}
.foot{
  text-align: center;
  margin-bottom: 10px;
}
.footText{
  font-size: 2rem;
}
/* Bacaan Page */

.galleryImg{
  height: 400px;
  object-fit: cover;
  width: 100%;
}
.bumiBg{
  background-image: url("static/images/bumi-bg.webp");
  background-size: cover;
}
.bulanBg{
  background-image: url("static/images/bumibulan-bg.webp");
  background-size: cover;
  background-position: bottom;
}
.futureBg{
  background-image: url("static/images/future.jpg");
  background-size: cover;
}
.solarBg{
  background-image: url("static/images/solar-bg.png");
  background-size: cover;
  background-position: center;
}
.penguinBg{
  background-image: url("static/images/penguin-bg.webp");
  background-size: cover;
  background-position: center;
}
.bacaanTitleTxt{
  padding: 20px;
  padding-top: 100px;
  font-family: "Rampart One";
  font-size: 2em;
  color: white;
  text-shadow: 2px 2px black;
}

/* Kereta Terbang Page*/
.keretaTerbangContainer{
  background-image: url("static/images/future.jpg");
  opacity: .9;
  background-color: #e5e4e4;
  background-size: cover;
  padding-top: 95px;
  text-align: center;
}
/* Plane Page */
.planeBg{
  background-image: url("static/images/plane-bg.jpg");
  background-size: cover;
  background-position: center;
}
.planeContainer{
  background-image: url("static/images/plane-bg.jpg");
  opacity: .8;
  background-size: cover;
  text-align: center;
}
/* Elektrik Page */
.elektrikBg{
  background-image: url("static/images/elektrik-bg.webp");
  background-size: cover;
}
.elektrikContainer{
  background-image: url("static/images/elektrik-bg.webp");
  opacity: .8;
  background-size: cover;
  text-align: center;
}
/* Ramalan Alam Page*/
.ramalanContainer{
  background-image: url("static/images/ramalan-bg.jpg");
  text-align: center;
  padding: 100px 50px 0px 50px;
}
.whiteFont{
  color: white;
  font-family: "Ubuntu Condensed";
  font-size: 1.5vw !important;
}
.yearTxt{
  font-family: "Aquire";
  font-size: xx-large;
}
.planetTitle{
  color:#fff200;
  font-family: "Aquire";
  font-size: 2vw !important;
}
/* Juara Page*/
.juaraTitle{
  font-family: "Rampart One";
  color: #fff200;
  font-size: 3vw !important;
  text-shadow: 2px 2px #ff0000;
  text-align: center;
}
.juaraContainer{
  background-color: white;
}

/* Popup container */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* The actual popup (appears on top) */
.popup .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class when clicking on the popup container (hide and show the popup) */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}

/* Telur Terapung Page */
.eksPageContainer{
  padding-top: 100px;
  padding-bottom: 95px;
  overflow-x: hidden;
  text-align: justify;
}
.eksSmallTitle{
  text-align: center;
  font-size: 2vw;
}
.eksImg, .stepImg{
  margin: auto; 
  display: block;
}
.stepImg{
  max-width: 250px;
}
/* kod rahsia page */
.kodBtn{
  background-color: blue;
  color: white;
}
.kodBtn:hover{
  background-color: darkblue;
  color: white;
}
.txtArea{
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}
/* seni pixel page */
.cenPixel{
  margin-left: 3rem;
}
.pixelTitle{
  font-family: "Pixelify Sans";
  color: #fff200;
  font-size: 5vw !important;
  text-shadow: 2px 2px #ff0000;
  text-align: center;
}
.lego-container{
  margin-top: 7rem !important;
  justify-content: center;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(32, 0fr);
  color: white;
 }
.center-content {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card:hover .img-fluid {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
 
.clickableImg {
  cursor: pointer;
}

.indCell{
  border: solid 0.5px grey;
  border-radius : 50%;
  width: 1.3vw;
  height: 1.3vw;
}

.indCellPro{
  border: solid 0.5px grey;
  border-radius : 50%;
  width: 1vw;
  height: 1vw;
}

.beliPixelImg{
  max-width: 400px;
}

.galleryImg{
  min-width: 100px !important;
  height: fit-content;
  margin-bottom: 1em;
}
 
.beliPixelImg:hover{
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

 /* Login Page*/
.loginContainer{
  max-width: 300px;
}
 
.btnLogin{
  line-height: 30px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
  font-family : 'Ubuntu Condensed';
  font-size: large;
  border: #282c34 solid 0.5px;
  text-decoration: none;
  color: black !important;
}

.mobileNav{
  display: none;
}

/* Profile Page */
.profileContainer{
  background-color: white;
  padding: 20px;
}
.topTable{
  background-image: url('static/images/profile-bg.webp');
  padding: 30px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.profileImgContainer{
  position: relative;
  top: 0;
  left: 0;
}
.profileImage{
  position: relative;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  border: 2px solid black;
  border-radius: 100px;
}
.avatarImg{
  position: relative;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border: 2px solid black;
  border-radius: 100px;
  margin: 5px;
  cursor: pointer;
}
.editImg{
  position: absolute;
  top: 0px;
  left: 46px;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  opacity: 0;
}
.editImg:hover{
  cursor: pointer;
  opacity: 0.5;
  transition: 0.5s;
}
.nameText, .introText{
  color: white;
}
.locInput{
  max-width: 30%;
}
.bdateContainer{
  max-width: 50%;
  margin-left: auto;
  margin-right: auto; 
}

/* Sampah Angkasa Page */
.sampahBg{
  background-image: url("static/images/space-debris.png");
  background-size: cover;
}
.angkasaContainer{
  overflow: hidden;
  background-image: url("static/images/angkasa-bg-mid.webp");
  background-size: cover;
  background-position: top;
}
.angkasaTxtContainer{
  text-align: center;
  width: 50%;
  margin: auto;
}
.angkasaTxt, #angkasaContainer p{
  color: white;
  font-family: "Poppins";
}
.familyPortraitImg{
  width: 10vw;
  margin-left: 6vw;
  animation: shakeRotateAnim 20s ease 0s infinite normal forwards;
}
.ammoniaImg{
  transform: rotate(0.2turn);
  width: 35vw;
}
.bagpackImg{
  margin-left: 4vw;
  width: 20vw;
  transform: rotate(0.9turn);
}
.glovesImg{
  margin-left: 4vw;
  width: 12vw;
  transform: rotate(0.9turn);
}
.deadAnimalImg{
  width: 20vw;
}
.cameraImg{
  width: 10vw;
  transform: rotate(0.6turn);
}
.spaceStationImg{
  width: 45vw;
  animation: spaceStationAnim 10s ease 0s infinite normal forwards;
}
.pizzaImg{
  width: 5vw;
}
.toiletImg{
  width: 18vw;
  animation: wobbleBtmAnim 10s ease 0s infinite normal forwards;
}
.lightningImg{
  width: 30vw;
  margin-left: 10vw;
  animation: spaceStationAnim 30s ease 0s infinite normal forwards;
}
.marg10{
  margin: 0 auto;
  width: 75%;
  margin-top: 11vw;
  margin-bottom: 10vw;
  align-items: center;
}
/* Pos Fakta AI page*/
.aiQContainer{
  max-width: 350px;
  margin: auto;
}
.inputAI{
  max-width: 350px;
  margin: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.textAI{
  font-family: "Poppins" !important;
}
.aiTitle{
  font-family: "Audiowide" !important;
}
.resultTxt{
  padding: 2px;
  text-align: justify;
}
.resultBox{
  max-width: 350px;
  margin: auto;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 6px 3px hsl(160, 10%, 90%);
  background-color: white;
  margin-bottom: 10px;
  padding: 5px;
}
.imgPlace{
  background-color: #555;
  height: 150px;
  max-width: 350px;
  text-align: center;
  padding: 60px 0px;
}
.imgTxt{
  color: white;
}
.langLink{
  text-decoration: none !important;
}
.btmBtn{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.genImgBtn{
  background-color: #ccc;
  margin-top: 10px;
  margin-bottom: -1px;
  padding: 10px;
  border-radius: 0.5rem;
  cursor: pointer;
}
.genImgBtn:hover{
  background-color: #a5a5a5;
}
.langContainer{
  margin: auto;
  max-width: 350px;
  display: flex;
  justify-content: flex-end;
}
.langImg{
  width: 25px;
  margin-right: 5px;
}
.hover-grayscale:hover {
  filter: grayscale(0%);
}
.grayscale{
  filter: grayscale(100%);
}
.alertContainer{
  position: fixed;
  bottom: 10px;
  left: 10px;
  max-width: 350px !important;
  margin: auto;
  padding: 10px !important;
  display: none;
}
.copied{
  display: block !important;
}
.copyIcon:hover{
  font-size: large;
}
/* Aku vs Netizen Page */

.netContainer{
  background-image: url("static/images/spider-bg.webp");
  background-color: white;
  background-position: center;
}
.boxContainer{
  max-width: 400px !important;
  column-gap: 10px;
}
.box{
  background-color: #fff;
  border-style: solid;
  border-color: #2f5d72;
  border-width: 1px 5px 5px 1px;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.boxAns{
  border-width: 1px 5px 5px 1px;
}
.flexD{
  margin-top: -10px !important;
  max-width: 300px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.progressBar{
  width: 300px;
  height: 30px;
  margin-bottom: 10px;
  border: 1px solid  #2f5d72;
}
progress {
  accent-color:#2f5d72;
  background-color:white;
  -webkit-appearence:none;
}
progress::-webkit-progress-bar {
  background-color:white;
}
progress::-webkit-progress-value {
  background-color: #2f5d72;
}
progress::-moz-progress-bar {
  background-color:#2f5d72;
}
.netizenTitle{
  font-size: 5vw !important;
  text-shadow: 2px 2px #767676;
  text-align: center;
}
.topText{
  font-family: "Playwrite GB S";
  color: #2f5d72;
}
.netText{
  font-weight: 600;
  font-family: "Poppins";
}

.shadowText{
  color: black;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

@keyframes wobbleBtmAnim {
	0%,
	100% {
		transform: translateX(0%);
		transform-origin: 50% 50%;
	}

	15% {
		transform: translateX(-30px) rotate(-6deg);
	}

	30% {
		transform: translateX(15px) rotate(6deg);
	}

	45% {
		transform: translateX(-15px) rotate(-3.6deg);
	}

	60% {
		transform: translateX(9px) rotate(2.4deg);
	}

	75% {
		transform: translateX(-6px) rotate(-1.2deg);
	}
}

@keyframes shakeRotateAnim {
	0%,
	100% {
		transform: rotate(0deg);
		transform-origin: 50% 50%;
	}

	10% {
		transform: rotate(8deg);
	}

	20%,
	40%,
	60% {
		transform: rotate(-10deg);
	}

	30%,
	50%,
	70% {
		transform: rotate(10deg);
	}

	80% {
		transform: rotate(-8deg);
	}

	90% {
		transform: rotate(8deg);
	}
}
@keyframes spaceStationAnim {
	0%,
	100% {
		transform: translateX(0);
    transform: translateY(0);
	}

	10%,
	30%,
	50%,
	70% {
		transform: translateX(-160px);
    transform: translateY(-10px);
	}

	20%,
	40%,
	60% {
		transform: translateX(160px);
    transform: translateY(10px);
	}

	80% {
		transform: translateX(80px);
	}

	90% {
		transform: translateX(-80px);
	}
}
@media screen and (max-width: 768px) {
  .mobileNav{
    display: inline;
  }
  .desktopNav{
    display: none;
  }
  .bdateContainer{
    max-width: 100%;
  }
  .angkasaTxtContainer{
    text-align: center;
    width: 80%;
    margin: auto;
  }
}
@media screen and (max-width: 667px) {
    .bacaanTitle{
        padding: 40px;
    }
    .eksperimenContainer{
        text-align: center;
    }
    .titleTxtLG{
        font-size: 8vw !important;
    }
    .eksperimenTxt{
        font-size: 4vw !important;
    }
    .bacaanBtn{
        font-size: small;
    }
    .ftrText{
        font-size: x-small !important;
    }
    .korekTitle{
      font-size: 8vw !important;
    }
    /* lipatan kertas page */
    #imgMain {
      max-width: 70%;
    }
    #imgContainer {
        max-width: 80%;
    }
    #descContainer {
        max-width: 80%;
        font-size: x-small;
    }
    #arrow {
        visibility: hidden;
    }
    .chckbx{
      margin-top: 15px;
    }
    .footText{
        font-size: 1.25rem;
        text-align: center;
    }
    .itemTxt{
      font-size: 1rem;
    }
    .whiteFont{
      font-size: 3vw !important;
    }
    .order-sm-2 {
      order: 1;
    }
    
    .order-sm-1 {
      order: 2;
    }
    .juaraTitle{
      font-size: 8vw !important;
    }
    .eksSmallTitle{
      font-size: 5vw !important;
    }
    .interaktifTitle{
      font-size: 8vw !important;
    }
    /* Telur Terapung Page */

    /* Seni Pixel Page */
    .indCell{
      width: 3vw;
      height: 3vw;
    }
    .indCellPro{
      width: 2.5vw;
      height: 2.5vw;
    }
    .beliPixelImg{
      max-width: 300px;
    }
     .cenPixel{
      margin-left: 3vw;
    }
     /* Anak Malaysia Page*/

     .pmPic{
      max-width: 125px;
     }
     .saTxt{
      font-size: 3vw;
     }
     .saBuf{
      width: 1%;
     }
}

@media screen and (max-width: 1440px) and (min-width: 668px){
  .cenPixel{
    margin-left: 9vw;
  }
}
